import { useContext, useEffect, useState } from 'react'
import { LoginContext } from 'context/login'

import Button from '../ui/Button'
import SubNav from '../menu/SubNav'
import AuthService from 'services/auth'
import Hooks from 'components/tool/Hooks'
import { AuthContext, AuthContextProvider } from 'context/auth'


export default function Video(props) {
	const [loginState, loginDispatch] = useContext(LoginContext)
	const [, authDispatch] = useContext(AuthContext)
	const [ready, setReady] = useState(false)
	const width = Hooks()
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)
	const [green, setGreen] = useState(false)

	useEffect(async () => {
	 }, [ready])

	useEffect(async () => {
		if (loginState.auth.session_id === '' || loginState.auth.account_type === '') {
			AuthService.Me()
				.then((data) => {
					authDispatch({
						type: 'SET_AUTH',
						payload: {
							user: {
								email: data.email,
								firstname: data.firstname,
								lastname: data.lastname,
								profile_pic: data.profile_pic,
							},
							iam: data.iam,
							authenticated: true,
						},
					})

					loginDispatch({
						type: 'SET_LOGIN',
						payload: {
							auth: {
								session_id: data.session,
								account_type: data.session_type,
								wealth: data.steps.wealth,
								investment: data.steps.investment,
								kyc: data.steps.kyc,
								video: data.steps.video,
								signature: data.signature,
							},
						},
					})

					setReady(true)
				})
				.catch((err) => {
					authDispatch({
						type: 'SET_AUTH',
						payload: {
							user: {
								email: '',
								firstname: '',
								lastname: '',
								profile_pic: '',
							},
							iam: [],
							authenticated: false,
						},
					})
					setReady(true)
				})
			return () => { }
		} else {
			setReady(true)
		}
		return () => { }
	}, [])

	const onSubmit = async (data) => {
		setError("")
		setLoading(true)

		

		await AuthService.UpdateVideo()
			.then((data) => {
				setLoading(false)
				loginDispatch({
					type: 'SET_LOGIN',
					payload: {
						auth: {
							session_id: loginState.auth.session_id,
							account_type: loginState.auth.account_type,
							wealth: loginState.auth.wealth,
							investment: loginState.auth.investment,
							kyc: loginState.auth.kyc,
							video: true,
							signature: loginState.auth.signature,
						},
					},
				})
				props.onNext()
			})
			.catch((err) => {
				setLoading(false)
				setError(err.message)
			})
		return () => { }
	}

	async function goBack() {
		setLoading(true)
		let bodyFormData = new FormData()
		bodyFormData.append('step', 'INVESTMENT')

		await AuthService.Undo(bodyFormData)
			.then((data) => {
				setLoading(false)
				loginDispatch({
					type: 'SET_LOGIN',
					payload: {
						auth: {
							session_id: loginState.auth.session_id,
							account_type: loginState.auth.account_type,
							wealth: loginState.auth.wealth,
							investment: false,
							kyc: loginState.auth.kyc,
							video: loginState.auth.video,
							signature: loginState.auth.signature,
						},
					},
				})
				props.onBack()
			})
			.catch((err) => {
				setLoading(false)
			})
		return () => { }
	}

	return (
		<div>
			<div>
				<SubNav></SubNav>
				<div className='max-w-7xl mx-auto relative'>
					<div className='dashboard-container'>
						<div className='bg-white rounded-xl p-6 shadow text-center'>
							<div className='flex justify-center'>
								<div className='w-1/2 text-center justify-center'>
									<p
										className="block text-center font-medium text-gray-700 mb-2">
										This process will open in a new window.
									</p>
									
									{loginState.auth.account_type === "CORPORATE" ? 
									<p
										className="block text-center font-medium text-gray-700 mb-2">
											For the final step, as corporate officer of this legal entity, please follow this link to complete video verification.
									</p>
									:
									<p
										className="block text-center font-medium text-gray-700 mb-2">
										Please complete the identification process and return to this page to finish the KYC questionnaire
									</p>
									}
									{!green ? 
									<a rel="noreferrer" onClick={() => setGreen(true)} href={"https://go.online-ident.ch/sygnumvideo/userdata"} className="block px-4 py-3 text-white font-bold mt-5 cursor-pointer  hover:bg-orange-500 flex transition ease-in duration-150 justify-center border  bg-orange-400 rounded-xl shadow-sm text-sm font-medium focus:outline-none focus:ring-2" target='_blank'>Start Verification</a>
									: 
									<a rel="noreferrer" href={"https://go.online-ident.ch/sygnumvideo/userdata"} className="block px-4 py-3 text-white font-bold mt-5  flex transition ease-in duration-150 justify-center border  bg-green-600 rounded-xl shadow-sm text-sm font-medium focus:outline-none focus:ring-2" target='_blank'>Questionaire completed</a>
								}
									</div>
								</div>
								<div className='flex justify-between mt-10'>
									<div>
									<Button
												type='submit'
												full
												loading={loading}
												label='Back'
												onClick={() => goBack()}
											/>
									</div>
									<div>
									</div>
								</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
