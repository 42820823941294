import { useContext, useEffect, useState } from 'react'
import { LoginContext } from 'context/login'

import Button from '../ui/Button'
import SubNav from '../menu/SubNav'
import ListSelector from '../ui/ListSelector'
import AuthService from 'services/auth'
import Hooks from 'components/tool/Hooks'
import { AuthContext, AuthContextProvider } from 'context/auth'
import { useForm } from 'react-hook-form'
import Wallet from "../wallet/index";
import { faWallet } from '@arcane-ui/pro-duotone-svg-icons'
import Input from 'components/ui/Input'
import Upload from '../ui/Upload'

export default function Investment(props) {
    const [loginState, loginDispatch] = useContext(LoginContext)
    const [, authDispatch] = useContext(AuthContext)
    const [ready, setReady] = useState(false)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [investmentValue, setInvestmentValue] = useState("")
    const [proofFile, setProofFile] = useState(null)
    const [disable, setDisable] = useState(true)
    const [provider, setProvider] = useState("")
    const [wallet, setWallet] = useState("")
    const [access, setAccess] = useState(true)
    const [transactionDone, setTransactionDone] = useState(true)

    var walletProviders = [
        {
            "value": "Metamask",
            "label": "Metamask",
        },
        {
            "value": "Ledger",
            "label": "Ledger",
        },
        {
            "value": "Coinbase",
            "label": "Coinbase",
        },
        {
            "value": "Other",
            "label": "Other",
        }
    ]

    useEffect(() => {
        if (loginState.auth.session_id === '' || loginState.auth.account_type === '' || loginState.auth.nonce === '') {
            AuthService.Me()
                .then((data) => {
                    authDispatch({
                        type: 'SET_AUTH',
                        payload: {
                            user: {
                                email: data.email,
                                firstname: data.firstname,
                                lastname: data.lastname,
                                profile_pic: data.profile_pic,
                            },
                            iam: data.iam,
                            authenticated: true,
                        },
                    })

                    loginDispatch({
                        type: 'SET_LOGIN',
                        payload: {
                            auth: {
                                session_id: data.session,
                                account_type: data.session_type,
                                wealth: data.steps.wealth,
                                investment: data.steps.investment,
                                kyc: data.steps.kyc,
                                video: data.steps.video,
                                signature: data.signature,
                            },
                        },
                    })

                    setReady(true)
                })
                .catch((err) => {
                    authDispatch({
                        type: 'SET_AUTH',
                        payload: {
                            user: {
                                email: '',
                                firstname: '',
                                lastname: '',
                                profile_pic: '',
                            },
                            iam: [],
                            authenticated: false,
                        },
                    })
                    setReady(true)
                })
            return () => { }
        } else {
            setReady(true)
        }
        return () => { }
    }, [])

    async function Submit(value) {
        setError("")
        setLoading(true)

        await AuthService.Me()
                .then((data) => {
                    if (access === true && data.signature.status !== 2) {
                        setError("Missing signature")
                        setLoading(false)
                        return
                    }
                })
                .catch((err) => {
                    console.log(err)
                })

        

        let bodyFormData = new FormData()
        bodyFormData.append('investment', investmentValue)
        bodyFormData.append('wallet', wallet)
        bodyFormData.append('provider', provider)
        if (proofFile !== null) {
			bodyFormData.append('file', proofFile)
		}


        await AuthService.UpdateInvestment(bodyFormData)
            .then((data) => {
                setLoading(false)
                loginDispatch({
                    type: 'SET_LOGIN',
                    payload: {
                        auth: {
                            session_id: loginState.auth.session_id,
                            account_type: loginState.auth.account_type,
                            wealth: true,
                            investment: true,
                            kyc: loginState.auth.kyc,
                            video: loginState.auth.video,
                            signature: loginState.auth.signature,
                        },
                    },
                })
                props.onNext()
            })
            .catch((err) => {
                setLoading(false)
                setError(err.message)
            })
        return () => { }
    }


    function UpdateInvestmentValue(value) {
        if (investmentValue === value) {
            setInvestmentValue("")
            setDisable(true)
        } else {
            setDisable(false)
            setInvestmentValue(value)
        }
    }

    async function goBack() {
        setLoading(true)
        let bodyFormData = new FormData()
        bodyFormData.append('step', 'WEALTH')

        await AuthService.Undo(bodyFormData)
            .then((data) => {
                setLoading(false)
                loginDispatch({
                    type: 'SET_LOGIN',
                    payload: {
                        auth: {
                            session_id: loginState.auth.session_id,
                            account_type: loginState.auth.account_type,
                            wealth: false,
                            investment: loginState.auth.investment,
                            kyc: loginState.auth.kyc,
                            video: loginState.auth.video,
                            signature: loginState.auth.signature,
                        },
                    },
                })
                props.onBack()
            })
            .catch((err) => {
                setLoading(false)
                setError(err.message)
            })
        return () => { }
    }

    return (
        <div>
            <div>
                <SubNav></SubNav>
                <div className='max-w-7xl mx-auto relative'>
                    <div className='dashboard-container'>
                        <div className='bg-white rounded-xl p-6 shadow'>
                                <div>
                                    <label
                                        className="block text-sm font-medium text-gray-700 mb-2">
                                        How are you planning to fund your investment ?
                                    </label>
                                    <div className='flex mb-5'>
                                        <div onClick={() => UpdateInvestmentValue("FIAT")} className="flex mr-5 cursor-pointer">
                                            <input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
                                                type="checkbox"
                                                checked={investmentValue === "FIAT"}
                                                />
                                                <div>
													<span className="text-sm align-middle font-medium text-primary-700">FIAT</span>
												</div>
                                        </div>
                                        <div onClick={() => UpdateInvestmentValue("CRYPTO")} className="flex mr-5 cursor-pointer">
                                            <input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
                                                type="checkbox"
                                                checked={investmentValue === "CRYPTO"} />
                                                <div>
													<span className="text-sm align-middle font-medium text-primary-700">CRYPTO</span>
												</div>
                                        </div>
                                    </div>
                                </div>
                                {investmentValue === "CRYPTO" ?
                                    <>
                                        <div className='mb-5'>
                                            <label
                                                className="block text-sm font-medium text-gray-700 mb-2">
                                                From which wallet do you plan to send crypto ?
                                            </label>
                                            <div className='w-1/2'>
                                                <ListSelector selected={provider} choices={walletProviders} onSelect={(e) => setProvider(e)}></ListSelector>
                                            </div>
                                        </div>
                                        {provider !== "" ? <>
                                            <label
                                                className="block text-sm font-medium text-gray-700 mb-2">
                                                Is the transaction already done ?
                                            </label>
                                            <div className='flex mb-5'>
                                                <div onClick={() => setTransactionDone(true)} className="flex mr-5 cursor-pointer">
                                                    <input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
                                                        type="checkbox"
                                                        checked={transactionDone}
                                                        />
                                                        <div>
                                                            <span className="text-sm align-middle font-medium text-primary-700">YES</span>
                                                        </div>
                                                </div>
                                                <div onClick={() => setTransactionDone(false)} className="flex mr-5 cursor-pointer">
                                                    <input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
                                                        type="checkbox"
                                                        checked={!transactionDone}
                                                         />
                                                        <div>
                                                            <span className="text-sm align-middle font-medium text-primary-700">NO</span>
                                                        </div>
                                                </div>
                                            </div>
                                            {transactionDone ? <>
                                                <label
                                                className="block text-sm font-medium text-gray-700 mb-2">
                                                Do you have access to the wallet ?
                                            </label>
                                            <div className='flex mb-5'>
                                                <div onClick={() => setAccess(true)} className="flex mr-5 cursor-pointer">
                                                    <input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
                                                        type="checkbox"
                                                        checked={access} />
                                                        <div>
                                                            <span className="text-sm align-middle font-medium text-primary-700">YES</span>
                                                        </div>
                                                </div>
                                                <div onClick={() => setAccess(false)} className="flex mr-5 cursor-pointer">
                                                    <input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
                                                        type="checkbox"
                                                        checked={!access}
                                                     />
                                                        <div>
                                                            <span className="text-sm align-middle font-medium text-primary-700">NO</span>
                                                        </div>
                                                </div>
                                            </div>
                                            {access ? <>
                                                <label
                                                className="block text-sm font-medium text-gray-700 mb-5">
                                                Please sign the message with the wallet used for your transaction: 
                                            </label>
                                            <div className="flex mt-5 justify-center">
                                                <div className="cursor-pointer hover:border-primary-700 focus:ring-primary-500 hover:bg-primary-600 flex transition ease-in duration-150 justify-center border  bg-primary-500 rounded-xl shadow-sm text-sm font-medium focus:outline-none focus:ring-2">
                                                <Wallet
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="block px-4 py-3 text-white font-bold "
                                                >
                                                </Wallet>
                                                </div>
                                            </div>
                                            </> : 
                                            <>
                                                <label
                                                    className="block text-sm font-medium text-gray-700 mb-5">
                                                    Please upload your transaction e-mail confirmation sent to you by your provider, or alternatively a screenshot of your wallet interface, on which ideally the following details are visible, depending on your provider:<br></br>
                                                    - Blockchain Transaction Hash/ID of the Transaction to Sygnum<br></br>
                                                    - Provider name applicable (logo, URL)<br></br>
                                                    - Client name, email or technical ID<br></br>
                                                    Please don&apos;t upload evidence from a public Block Explorer such as Blockchain.com, Etherscan.io and the like.
                                                </label>
                                                <Upload
                                                    loading={loading}
                                                    acceptedFileTypes={[
                                                        'image/jpeg',
                                                        'image/png',
                                                        'image/jpg',
                                                    ]}
                                                    setFile={setProofFile}></Upload>
                                            </>
                                            }
                                            </> : 
                                            <>
                                                <label
                                                        className="block text-sm font-medium text-gray-700 mb-2">
                                                        Please provide wallet address of sending address. Upon transfer, kindly email the screenshot to <a className='font-bold' href="mailto:aliya.dasgupta@sygnum.com">aliya.dasgupta@sygnum.com</a>, showing your transaction e-mail confirmation sent to you by your provider, or alternatively a screenshot of your wallet interface, on which ideally the following details are visible, depending on your provider:<br></br>
                                                        - Blockchain Transaction Hash/ID of the Transaction to Sygnum<br></br>
                                                        - Provider name applicable (logo, URL)<br></br>
                                                        - Client name, email or technical ID<br></br>
                                                        Please don&apos;t upload evidence from a public Block Explorer such as Blockchain.com, Etherscan.io and the like.
                                                    </label>
                                                    <Input
                                                        setValue={(e) => setWallet(e)}
                                                        icon={faWallet}
                                                        label=''
                                                        placeholder='0x000000000000000000'
                                                        name='email'
                                                    />
                                                </>
                                            }
                                        </> : 
                                        null
                                        }
                                    </>
                                    : null}

                                {error !== "" ? <p className="mt-2 text-sm text-red-600" id="email-error">
                                    {error}
                                </p> : null}

                                <div className='flex justify-between mt-10'>
                                    <div>
                                        <Button
                                            type='submit'
                                            full
                                            loading={loading}
                                            label='Back'
                                            onClick={() => goBack()}
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            disabled={disable}
                                            type='submit'
                                            theme={"success"}
                                            full
                                            loading={loading}
                                            label='Next'
                                            onClick={() => Submit()}
                                        />
                                    </div>
                                </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
