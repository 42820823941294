import { useContext, useEffect, useState } from 'react'
import { LoginContext } from 'context/login'
import {
	faCheckCircle,
} from '@arcane-ui/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@arcane-ui/react-fontawesome'

import Button from '../ui/Button'
import SubNav from '../menu/SubNav'
import {Synaps} from "@synaps-io/verify-sdk";

import AuthService from 'services/auth'
import Hooks from 'components/tool/Hooks'
import { AuthContext, AuthContextProvider } from 'context/auth'
import {
	faPassport,
} from '@arcane-ui/pro-duotone-svg-icons'

export default function Verification(props) {
	const [loginState, loginDispatch] = useContext(LoginContext)
	const [, authDispatch] = useContext(AuthContext)
	const [ready, setReady] = useState(false)
	const width = Hooks()
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const [policy, setPolicy] = useState(false)
	const [service, setService] = useState("")

	function InitSynaps(session, mode) {
		Synaps.init({
			sessionId: session,
			service: loginState.auth.account_type.toLowerCase(),
			mode: mode,
			containerId: mode === "modal" ? 'synaps-modal' : 'synaps-wrapper',
			withFinishButton: false,
            onFinish: function () {
                try {
                    const elements = document.querySelectorAll('[id=synaps-modal]');
                    for(var i = 0; i < elements.length; i++) {
                        elements[i].remove();
                    }
                } catch (e) {
                    console.log(e)
                } 
            },
		  })

		  if (mode === "modal") {
			Synaps.show()
		  }
	}


	useEffect(async () => {
		if (ready === true) {
			if (width < 768) {
				InitSynaps(loginState.auth.session_id, "modal")
			} else {
				InitSynaps(loginState.auth.session_id, "embed")
			}
		}
	}, [ready])

	useEffect(async () => {
		if (loginState.auth.session_id === '' || loginState.auth.account_type === '') {
			AuthService.Me()
				.then((data) => {
					authDispatch({
						type: 'SET_AUTH',
						payload: {
							user: {
								email: data.email,
								firstname: data.firstname,
								lastname: data.lastname,
								profile_pic: data.profile_pic,
							},
							iam: data.iam,
							authenticated: true,
						},
					})

					loginDispatch({
						type: 'SET_LOGIN',
						payload: {
							auth: {
								session_id: data.session,
								account_type: data.session_type,
								wealth: data.steps.wealth,
								investment: data.steps.investment,
								kyc: data.steps.kyc,
								video: data.steps.video,
								signature: data.signature,
							},
						},
					})

					setReady(true)
				})
				.catch((err) => {
					console.log(err)
					authDispatch({
						type: 'SET_AUTH',
						payload: {
							user: {
								email: '',
								firstname: '',
								lastname: '',
								profile_pic: '',
							},
							iam: [],
							authenticated: false,
						},
					})
					setReady(true)
				})
			return () => { }
		} else {
			setReady(true)
		}
		return () => { }
	}, [])

	

	const onSubmit = async () => {
		if (policy === false) {
			setError("Please accept the terms and conditions")
			return
		}

		setError("")
		setLoading(true)

		await AuthService.UpdateVerification()
			.then((data) => {
				setLoading(false)
				loginDispatch({
					type: 'SET_LOGIN',
					payload: {
						auth: {
							session_id: loginState.auth.session_id,
							account_type: loginState.auth.account_type,
							wealth: loginState.auth.wealth,
							investment: loginState.auth.investment,
							kyc: true,
							video: loginState.auth.video,
							signature: loginState.auth.signature,
						},
					},
				})
				props.onNext()
			})
			.catch((err) => {
				setLoading(false)
				setError(err.message)
			})
		return () => { }
	}

	const handleOpen = () => {
		Synaps.show()
	  }

	return (
		<div>
			<div>
				<SubNav></SubNav>
				<div className='mx-auto relative'>
					<div className='dashboard-container'>
						<div className='md:flex mx-auto md:justify-between mt-3'>
							<div className={`${width >= 768 ? 'block' : 'hidden'} mt-5 mx-auto bg-white mr-5 ml-5 rounded-xl p-4 shadow md:w-2/3`}>
								<div className='synaps-modal' id="synaps-wrapper"></div>
							</div>

							<div className={`${width >= 768 ? 'hidden' : 'block'} mt-5 text-center mx-auto bg-white mr-5 ml-5 rounded-xl p-4 shadow md:w-2/3`}>
								<FontAwesomeIcon
									className={'text-gray-500 text-3xl mx-auto mt-3 text-3xl mt-0.5'}
									icon={faPassport}></FontAwesomeIcon>
								<p className="mt-1">Click on the following button to start verification process</p>
								<button type="submit" id="synaps-btn" onClick={handleOpen}
									className="mx-auto my-2 mx-auto mt-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-500 hover:bg-primary-400 focus:outline-none focus-primary-btn active:bg-primary-700 transition duration-150 ease-in-out">Verify my identity
								</button>
							</div>

							<div className="mt-5 md:w-1/3 mr-5 ml-5 mx-auto">
								<div className='bg-white rounded-xl p-6 shadow'>
									<h3 className="text-2xl text-primary-800 mb-1 font-bold">Start verification</h3>
									<div className="flex mt-5">
										<div>
											<p><FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-3 mt-0.5" /></p>
										</div>
										<div>
											Complete each verification step until each status shows <span className="text-green-500">Verified</span> or <span className="text-orange-500">Pending</span>
										</div>
									</div>

									<div className="flex mt-5">
										<div>
											<p><FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-3 mt-0.5" /></p>
										</div>
										<div>
											Wait for Synaps to verify your data. The time needed for verification varies, depending on the project size and verification requirements.
										</div>
									</div>

									<div className="flex mt-5">
										<div>
											<p><FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-3 mt-0.5" /></p>
										</div>
										<div>
											You will receive an e-mail notification if any document(s) are <span className="text-red-500">Rejected</span>.
										</div>
									</div>

									<div className="flex mt-5">
										<div>
											<p><FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-3 mt-0.5" /></p>
										</div>
										<div>
											Once every step has been <span className="text-green-500">Verified</span>, you receive an e-mail notifying that we have accepted your application.
										</div>
									</div>

									<div className=" mt-5">
										<div className='flex'>
										<div>
											<p><FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-3 mt-0.5" /></p>
										</div>
										<div>
											Review terms and conditions
										</div>
										</div>
										
										<div className='mt-4'> 
												<div className='flex mb-5'>
													<div onClick={() => setPolicy(!policy)} className="flex mr-5 cursor-pointer">
														<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
															type="checkbox"
															checked={policy}
															/>
															<div>
																<span className="text-sm align-middle font-medium text-primary-700">I have read and accept Sygnum's <a className='underline' href='https://eur01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.sygnum.com%2Fprivacy-notice%2F&data=05%7C01%7Caliya.dasgupta%40sygnum.com%7C9b4d24b00fcf474e074908dbb9f3b67f%7C26811474e4034cc192038bcfd0c27bd2%7C0%7C0%7C638308228616345711%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=Y5fOq40A6jribE0gE%2B5U7%2BQTgUC0mXxMNj4ttIwsFWk%3D&reserved=0' target='_blank'>Privacy Notice</a></span>
															</div>
													</div>
													</div>
											</div>
									</div>

								</div>
								<div className='bg-white rounded-xl p-6 mt-5 shadow'>
									<div className='flex justify-between'>
										<div>
										</div>
										{error !== "" ? <p className="mt-2 text-sm text-red-600" id="email-error">
											{error}
										</p> : null}
										<div>
										<Button
                                            type='submit'
                                            theme={"success"}
                                            full
											disabled={policy === false}
                                            loading={loading}
                                            label='Next'
                                            onClick={() => onSubmit()}
                                        />
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
