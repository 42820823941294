import { useContext, useEffect, useState } from 'react'
import { LoginContext } from 'context/login'
import {
	faBuilding,
	faBusinessTime,
	faCheckCircle, faDollarSign, faEnvelope, faUser,
} from '@arcane-ui/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@arcane-ui/react-fontawesome'

import Button from '../ui/Button'
import SubNav from '../menu/SubNav'
import AuthService from 'services/auth'
import Hooks from 'components/tool/Hooks'
import { AuthContext, AuthContextProvider } from 'context/auth'
import Input from 'components/ui/Input'
import { useForm } from 'react-hook-form'
import TextArea from '../ui/Textarea'

export default function Wealth(props) {
	const [loginState, loginDispatch] = useContext(LoginContext)
	const [, authDispatch] = useContext(AuthContext)
	const [ready, setReady] = useState(false)
	const width = Hooks()
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)
	const { register, handleSubmit, errors } = useForm()
	const [wealth, setWealth] = useState("")
	const [source, setSource] = useState("")
	const [income, setIncome] = useState("")
	const [employer, setEmployer] = useState("")
	const [description, setDescription] = useState("")
	const [disable, setDisable] = useState(true)
	

	useEffect(async () => {
		setDisable(true)
		if (wealth !== "" && employer !== "" && source !== "" && description !== "" && income !== "") {
			setDisable(false)
		}

	}, [description, employer])

	useEffect(async () => {
		if (loginState.auth.session_id === '' || loginState.auth.account_type === '') {
			AuthService.Me()
				.then((data) => {
					authDispatch({
						type: 'SET_AUTH',
						payload: {
							user: {
								email: data.email,
								firstname: data.firstname,
								lastname: data.lastname,
								profile_pic: data.profile_pic,
							},
							iam: data.iam,
							authenticated: true,
						},
					})

					loginDispatch({
						type: 'SET_LOGIN',
						payload: {
							auth: {
								session_id: data.session,
								account_type: data.session_type,
								wealth: data.steps.wealth,
								investment: data.steps.investment,
								kyc: data.steps.kyc,
								video: data.steps.video,
								signature: data.signature,
							},
						},
					})

					setReady(true)
				})
				.catch((err) => {
					authDispatch({
						type: 'SET_AUTH',
						payload: {
							user: {
								email: '',
								firstname: '',
								lastname: '',
								profile_pic: '',
							},
							iam: [],
							authenticated: false,
						},
					})
					setReady(true)
				})
			return () => { }
		} else {
			setReady(true)
		}
		return () => { }
	}, [])

	const onSubmit = async (data) => {
		setError("")
		setLoading(true)

		if (wealth === "") {
			setError("Missing total wealth information")
			return
		}

		if (source === "") {
			setError("Missing source of wealth information")
			return
		}

		if (income === "") {
			setError("Missing Annual Income information")
			return
		}

		if (employer === "") {
			setError("Missing employer information")
			return
		}

		if (description === "") {
			setError("Missing description information")
			return
		}

		let bodyFormData = new FormData()
		bodyFormData.append('wealth', wealth)
		bodyFormData.append('source', source)
		bodyFormData.append('income', income)
		bodyFormData.append('employer', employer)
		bodyFormData.append('description', description)

	
		await AuthService.UpdateIndividualWealth(bodyFormData)
			.then((data) => {
				setLoading(false)
				loginDispatch({
					type: 'SET_LOGIN',
					payload: {
						auth: {
							session_id: loginState.auth.session_id,
							account_type: loginState.auth.account_type,
							wealth: true,
							investment: loginState.auth.investment,
							kyc: loginState.auth.kyc,
							video: loginState.auth.video,
							signature: loginState.auth.signature,
						},
					},
				})
				props.onNext()
			})
			.catch((err) => {
				setLoading(false)
				setError(err.message)
			})
		return () => { }
	}

	const formSchema = {}

	function UpdateWealthValue(value) {
		if (wealth === value) {
			setWealth("")
			setDisable(true)
		} else {
			setWealth(value)
			if (wealth !== "" && employer !== "" && source !== "" && description !== "" && income !== "") {
				setDisable(false)
			}
		}
	}

	function UpdateIncomeValue(value) {
		if (income === value) {
			setIncome("")
			setDisable(true)
		} else {
			setIncome(value)
			if (wealth !== "" && employer !== "" && source !== "" && description !== "" && income !== "") {
				setDisable(false)
			}
		}
	}

	function UpdateSourceValue(value) {
		if (source === value) {
			setSource("")
			setDisable(true)
		} else {
			setSource(value)
			if (wealth !== "" && employer !== "" && source !== "" && description !== "" && income !== "") {
				setDisable(false)
			}
		}
	}
	

	async function goBack() {
        setLoading(true)
        let bodyFormData = new FormData()
        bodyFormData.append('step', 'KYB')

        await AuthService.Undo(bodyFormData)
            .then((data) => {
                setLoading(false)
                loginDispatch({
                    type: 'SET_LOGIN',
                    payload: {
                        auth: {
                            session_id: loginState.auth.session_id,
                            account_type: loginState.auth.account_type,
                            wealth: loginState.auth.wealth,
                            investment: loginState.auth.investment,
                            kyc: false,
                            video: loginState.auth.video,
                            signature: loginState.auth.signature,
                        },
                    },
                })
                props.onBack()
            })
            .catch((err) => {
                setLoading(false)
                setError(err.message)
            })
        return () => { }
    }


	return (
		<div>
			<div>
				<SubNav></SubNav>
				<div className='max-w-7xl mx-auto relative'>
					<div className='dashboard-container'>
						<div className='bg-white rounded-xl p-6 shadow'>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div>
									<label
										className="block text-sm font-medium text-gray-700 mb-2">
										Please indicate your current total wealth :
									</label>
									<div className='flex'>
										<div onClick={() => UpdateWealthValue("<500'000")} className="flex mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={wealth === "<500'000"}
												/>
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">&#8804; 500 000</span>
												</div>
										</div>
										<div onClick={() => UpdateWealthValue("500'001-2'000'000")} className="flex ml-5 mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={wealth === "500'001-2'000'000"}
												 />
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">500 001 - 2 000 000</span>
												</div>
										</div>
										<div onClick={() => UpdateWealthValue("2'000'001-5'000'000")} className="flex ml-5 mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={wealth === "2'000'001-5'000'000"}
												 />
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">2 000 001 - 5 000 000</span>
												</div>
										</div>
										<div onClick={() => UpdateWealthValue("5'000'001-10'000'000")} className="flex ml-5 mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={wealth === "5'000'001-10'000'000"}
												 />
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">5 000 001 - 10 000 000</span>
												</div>
										</div>
									</div>
									<div className='flex'>
										<div onClick={() => UpdateWealthValue("10'000'001-25'000'000")} className="flex mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={wealth === "10'000'001-25'000'000"} />
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">10 000 001 - 25 000 000</span>
												</div>
										</div>
										<div onClick={() => UpdateWealthValue(">25'000'000")} className="flex ml-5 mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={wealth === ">25'000'000"}/>
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">&#62; 25'000'000</span>
												</div>
										</div>
										<div onClick={() => UpdateWealthValue(">50'000'000")} className="flex ml-5 mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={wealth === ">50'000'000"} />
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">&#62; 50 000 000</span>
												</div>
										</div>
									</div>
	
								</div>

								<div className='mt-5'>
									<label
										className="block text-sm font-medium text-gray-700 mb-2">
										Please provide an image of source of wealth :
									</label>
									<div className='flex'>
										<div onClick={() => UpdateSourceValue("Self-employment or Salaried income")} className="flex mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={source === "Self-employment or Salaried income"}
												/>
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">Self-employment or Salaried income</span>
												</div>
										</div>
										<div onClick={() => UpdateSourceValue("Inherance/gift")} className="flex ml-5 mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={source === "Inherance/gift"}
												 />
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">Inherance/gift</span>
												</div>
										</div>
										<div onClick={() => UpdateSourceValue("Sale of company")} className="flex ml-5 mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={source === "Sale of company"}
												 />
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">Sale of company</span>
												</div>
										</div>
										<div onClick={() => UpdateSourceValue("Sale of Real Estate")} className="flex ml-5 mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={source === "Sale of Real Estate"}
												 />
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">Sale of Real Estate</span>
												</div>
										</div>
									</div>

									<div className='flex'>
										<div onClick={() => UpdateSourceValue("Investment earnings")} className="flex mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={source === "Investment earnings"}
												/>
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">Investment earnings</span>
												</div>
										</div>
										<div onClick={() => UpdateSourceValue("Digital asset earnings")} className="flex ml-5 mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={source === "Digital asset earnings"}
												 />
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">Digital asset earnings</span>
												</div>
										</div>
										<div onClick={() => UpdateSourceValue("Pension Fund")} className="flex ml-5 mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={source === "Pension Fund"}
												 />
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">Pension Fund</span>
												</div>
										</div>
										<div onClick={() => UpdateSourceValue("Other (please specify)")} className="flex ml-5 mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={source === "Other (please specify)"}
												 />
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">Other (please specify)</span>
												</div>
										</div>
									</div>
	
								</div>

								<div className='mt-5'>
									<label
										className="block text-sm font-medium text-gray-700 mb-2">
										Annual Income (in CHF)
									</label>
									<div className='flex'>
										<div onClick={() => UpdateIncomeValue("<100'000")} className="flex mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={income === "<100'000"}
												/>
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">&#8804; 100 000</span>
												</div>
										</div>
										<div onClick={() => UpdateIncomeValue("100'001-250'000")} className="flex ml-5 mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={income === "100'001-250'000"}
												 />
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">100 001 - 250 000</span>
												</div>
										</div>
										<div onClick={() => UpdateIncomeValue("250'001-500'000")} className="flex ml-5 mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={income === "250'001-500'000"}
												 />
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">250 001 - 500 000</span>
												</div>
										</div>
										<div onClick={() => UpdateIncomeValue("500'001-1'000'000")} className="flex ml-5 mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={income === "500'001-1'000'000"}
												 />
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">500 001 - 1 000 000</span>
												</div>
										</div>
										<div onClick={() => UpdateIncomeValue(">1'000'000")} className="flex ml-5 mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={income === ">1'000'000"}
												 />
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">&#62; 1 000 000</span>
												</div>
										</div>
									</div>
	
								</div>
								
								<div className='mb-5 mt-5'>
									<Input
										setValue={(e) => setEmployer(e)}
										icon={faBuilding}
										label='Name of current or most recent employer'
										placeholder='Amazon'
										name='email'
									/>
								</div>
								<div className='mb-5 mt-5'>
									<label
										className="block text-sm font-medium text-gray-700 mb-2">
										Please provide a brief description of how you built your wealth
									</label>
									<TextArea
										setValue={(e) => setDescription(e)}
										icon={faBuilding}
										placeholder='Business description'
										name='email'
									/>
								</div>
								{error !== "" ? <p className="mt-2 text-sm text-red-600" id="email-error">
									{error}
								</p> : null}
								<div className='flex justify-between mt-10'>
									<div>
										<Button
                                            type='submit'
                                            full
                                            loading={loading}
                                            label='Back'
                                            onClick={() => goBack()}
                                        />
									</div>
									<div>
										<Button
											disabled={disable}
											type='submit'
											theme={"success"}
											full
											loading={loading}
											label='Next'
											onClick={() => onSubmit()}
										/>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
