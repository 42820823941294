import { IntegrationAPI } from 'services/axios'

export default {
	Init(request, config) {
		return IntegrationAPI.post('/api/users/init', request, config)
	},
	Login(data, config) {
		return IntegrationAPI.post('/api/users/login', data, config)
	},
	Me() {
		return IntegrationAPI.post('/api/users/me')
	},
	Logout() {
		return IntegrationAPI.post('/api/users/logout')
	},
	Signup(request, config) {
		return IntegrationAPI.post('/api/users/signup', request, config)
	},
	UpdateWealth(body) {
		return IntegrationAPI.post('/api/users/wealth', body)
	},
	UpdateIndividualWealth(body) {
		return IntegrationAPI.post('/api/users/individual/wealth', body)
	},
	UpdateInvestment(body) {
		return IntegrationAPI.post('/api/users/investment', body)
	},
	Undo(body) {
		return IntegrationAPI.post('/api/users/undo', body)
	},
	SignatureVerify(body) {
		return IntegrationAPI.post(`/api/users/signature`, body);
	},
	UpdateVideo() {
		return IntegrationAPI.post(`/api/users/video`, null);
	},
	UpdateVerification() {
		return IntegrationAPI.post(`/api/users/verification`, null);
	},
	RetrieveUBOs() {
		return IntegrationAPI.get(`/api/users/ubo`);
	},
	UpdateUBO(body) {
		return IntegrationAPI.put(`/api/users/ubo`, body);
	}
}
