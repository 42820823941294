import AuthGuard from 'guard/auth'
import Navbar from 'components/menu/Navbar'
import DashbordPage from 'components/pages/DashboardPage'

function Dashboard() {
	return (
		<AuthGuard>
			<div className='relative min-h-screen flex flex-col'>
				<Navbar></Navbar>
				<DashbordPage></DashbordPage>
			</div>
		</AuthGuard>
	)
}

export default Dashboard
