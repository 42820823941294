import {
  createAuthenticationAdapter,
  RainbowKitAuthenticationProvider,
} from "@rainbow-me/rainbowkit";
import { useMemo, useState, useContext } from "react";
import AuthService from 'services/auth'
import Cookie from "js-cookie";
import * as Notification from "components/ui/Notification";
import { LoginContext, LoginContextProvider } from 'context/login'

function AuthenticationProvider({ children }) {
  const [status, setStatus] = useState("unauthenticated");

  const authenticationAdapter = useMemo(() => {
    return createAuthenticationAdapter({
      getNonce: async () => {
        let nonce = await AuthService.Me()
                .then((data) => {
                    return data.signature.nonce
                })
                .catch((err) => {
                    console.log(err)
                })
        return nonce
      },

      createMessage: ({ nonce }) => {
        return `Synaps wants you to sign in with your account\nThis request will not trigger a blockchain transaction or cost any gas fees.\nNonce: ${nonce}`;
      },

      getMessageBody: ({ message }) => message.toString(),

      verify: async ({ message, signature }) => {
        const s_wallet = Cookie.get("s_wallet");

        let body = {
          "signature": signature,
          "challenge_encoded": message,
          "public_address": s_wallet,
        }
        await AuthService.SignatureVerify(body)
        .then(async (data) => {
          setStatus("authenticated");
        })
        .catch((err) => {
          Notification.error(err.message);
        });
        
        return true;
      },
    });
  }, []);

  return (
    <RainbowKitAuthenticationProvider
      adapter={authenticationAdapter}
      status={status}
    >
      {children}
    </RainbowKitAuthenticationProvider>
  );
}

export default AuthenticationProvider;
