import { useContext, useEffect, useState } from 'react'
import { LoginContext } from 'context/login'
import SubNav from '../menu/SubNav'
import Footer from '../menu/Footer'
import AuthService from 'services/auth'
import Hooks from 'components/tool/Hooks'
import { AuthContext, AuthContextProvider } from 'context/auth'
import WorkflowSteps from "./WorkflowSteps";
import Verification from "./Verification";
import WealthCorporate from "./Wealth_corporate";
import WealthIndividual from "./Wealth_individual";
import Video from "./Video";
import UBO from "./Ubo";
import Investment from "./Investment";

export default function DashboardPage() {
	const [loginState, loginDispatch] = useContext(LoginContext)
	const [, authDispatch] = useContext(AuthContext)
	const [ready, setReady] = useState(false)
	const [stepID, setStepID] = useState(0)
	const width = Hooks()

	const CORPORATE_STEPS_LIST = {
		0: "KYB",
		1: "WEALTH",
		2: "INVESTMENT",
		3: "VIDEO",
	}

	useEffect(async () => {
		if (loginState.auth.session_id === '' || loginState.auth.account_type === '') {
			AuthService.Me()
				.then((data) => {
					authDispatch({
						type: 'SET_AUTH',
						payload: {
							user: {
								email: data.email,
								firstname: data.firstname,
								lastname: data.lastname,
								profile_pic: data.profile_pic,
							},
							iam: data.iam,
							authenticated: true,
						},
					})

					if (data.steps.kyc === false) {
						setStepID(0)
					} else if (data.steps.wealth === false) {
						setStepID(1)
					} else if (data.steps.investment === false) {
						setStepID(2)
					} else if (data.steps.video === false) {
						setStepID(3)
					}

					loginDispatch({
						type: 'SET_LOGIN',
						payload: {
							auth: {
								session_id: data.session,
								account_type: data.session_type,
								wealth: data.steps.wealth,
								investment: data.steps.investment,
								kyc: data.steps.kyc,
								video: data.steps.video,
								signature: data.signature,
							},
						},
					})

					setReady(true)
				})
				.catch((err) => {
					authDispatch({
						type: 'SET_AUTH',
						payload: {
							user: {
								email: '',
								firstname: '',
								lastname: '',
								profile_pic: '',
							},
							iam: [],
							authenticated: false,
						},
					})
					setReady(true)
				})
			return () => { }
		} else {
			console.log(loginState.auth)
			if (loginState.auth.kyc === false) {
				setStepID(0)
			} else if (loginState.auth.wealth === false) {
				setStepID(1)
			} else if (loginState.auth.investment === false) {
				setStepID(2)
			} else if (loginState.auth.video === false) {
				setStepID(3)
			}
			setReady(true)
		}
		return () => { }
	}, [])

	return (
		<div>
			{ready ?
				<div>
					<WorkflowSteps current={CORPORATE_STEPS_LIST[stepID]} video={loginState.auth.video} ubo={loginState.auth.ubo} wealth={loginState.auth.wealth} investment={loginState.auth.investment} kyc={loginState.auth.kyc}></WorkflowSteps>
					<div>
						{CORPORATE_STEPS_LIST[stepID] === "VIDEO" ?
							<Video onNext={() => setStepID(stepID + 1)} onBack={() => setStepID(stepID - 1)}></Video>
							: null}
						{CORPORATE_STEPS_LIST[stepID] === "WEALTH" ?
						<>
							{loginState.auth.account_type === "CORPORATE" ?
							<WealthCorporate onBack={() => setStepID(stepID - 1)} onNext={() => setStepID(stepID + 1)}></WealthCorporate>
							: 
							<WealthIndividual onBack={() => setStepID(stepID - 1)} onNext={() => setStepID(stepID + 1)}></WealthIndividual>
							} </>
							: null}
						{CORPORATE_STEPS_LIST[stepID] === "INVESTMENT" ?
							<Investment onBack={() => setStepID(stepID - 1)} onNext={() => setStepID(stepID + 1)}></Investment>
							: null}
						{CORPORATE_STEPS_LIST[stepID] === "KYB" ?
							<Verification onBack={() => setStepID(stepID - 1)} onNext={() => setStepID(stepID + 1)} ></Verification>
							: null}
					</div>
					<div className='max-w-7xl mx-auto relative'>
						<Footer></Footer>
					</div>
				</div> : null}
		</div>
	)
}
