import {
	faCheckCircle,
	faExclamationCircle,
	faInfoCircle,
} from '@arcane-ui/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@arcane-ui/react-fontawesome'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

export function info(message) {
	toast.info(
		<div className="flex">
			<div className="mr-2">
				<FontAwesomeIcon
					className="text-bluegray-500 text-lg"
					icon={faInfoCircle}></FontAwesomeIcon>
			</div>
			<div>
				<p className="text-sm font-semibold text-primary-500">Info</p>
				<p className="mt-1 text-sm text-gray-500">{message}</p>
			</div>
		</div>
	)
}

export function error(message) {
	toast.error(
		<div className="flex">
			<div className="mr-2">
				<FontAwesomeIcon
					className="text-red-600 text-lg"
					icon={faExclamationCircle}></FontAwesomeIcon>
			</div>
			<div>
				<p className="text-sm font-semibold text-primary-500">Error</p>
				<p className="mt-1 text-sm text-gray-500">{message}</p>
			</div>
		</div>
	)
}

export function success(message) {
	toast.success(
		<div className="flex">
			{/* <div className="mr-2">
				<FontAwesomeIcon
					className="text-green-600"
					icon={faCheckCircle}></FontAwesomeIcon>
			</div> */}
			<div>
				<p className="text-sm font-semibold text-primary-500">Success</p>
				<p className="mt-1 text-sm text-gray-500">{message}</p>
			</div>
		</div>
	)
}
