import { Redirect } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext } from 'context/auth'

export default function AuthGuard({ children }) {
	const [auth] = useContext(AuthContext)

	let redirectURI = '/signup'

	return auth.authenticated ? children : <Redirect to={redirectURI} />
}
