import axios from "axios";
import Cookies from "js-cookie";
import { b64Decode } from "lib/b64";

export const IntegrationAPI = axios.create()

IntegrationAPI.interceptors.request.use((request) => {
	return request
})

IntegrationAPI.interceptors.response.use(
	(response) => {
		return response.data
	},
	(error) => {
		console.log(error)
		if (error.response === undefined) {
			return Promise.reject({
				api_code: 'NETWORK_ERROR',
				message: 'Network Error',
			})
		}
		return Promise.reject(error.response.data)
	}
)
