import { useContext, useEffect, useState } from 'react'
import { LoginContext } from 'context/login'
import {
	faBuilding,
	faBusinessTime,
	faCheckCircle, faDollarSign, faEnvelope, faUser,
} from '@arcane-ui/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@arcane-ui/react-fontawesome'

import Button from '../ui/Button'
import SubNav from '../menu/SubNav'
import AuthService from 'services/auth'
import Hooks from 'components/tool/Hooks'
import { AuthContext, AuthContextProvider } from 'context/auth'
import Input from 'components/ui/Input'
import { useForm } from 'react-hook-form'
import Upload from '../ui/Upload'

export default function Wealth(props) {
	const [loginState, loginDispatch] = useContext(LoginContext)
	const [, authDispatch] = useContext(AuthContext)
	const [ready, setReady] = useState(false)
	const width = Hooks()
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)
	const { register, handleSubmit, errors } = useForm()
	const [vehicleValue, setVehicleValue] = useState("")
	const [regulatedValue, setRegulatedValue] = useState("")
	const [investorsValue, setInvestorsValue] = useState("")
	const [balanceValue, setBalanceValue] = useState("")
	const [businessValue, setBusinessValue] = useState("")
	const [disable, setDisable] = useState(true)
	const [incomeValue, setIncomeValue] = useState("")
	const [statementFile, setStatementFile] = useState(null)

	useEffect(async () => { }, [ready])

	useEffect(async () => {
		if (loginState.auth.session_id === '' || loginState.auth.account_type === '') {
			AuthService.Me()
				.then((data) => {
					authDispatch({
						type: 'SET_AUTH',
						payload: {
							user: {
								email: data.email,
								firstname: data.firstname,
								lastname: data.lastname,
								profile_pic: data.profile_pic,
							},
							iam: data.iam,
							authenticated: true,
						},
					})

					loginDispatch({
						type: 'SET_LOGIN',
						payload: {
							auth: {
								session_id: data.session,
								account_type: data.session_type,
								wealth: data.steps.wealth,
								investment: data.steps.investment,
								kyc: data.steps.kyc,
								video: data.steps.video,
								signature: data.signature,
							},
						},
					})

					setReady(true)
				})
				.catch((err) => {
					authDispatch({
						type: 'SET_AUTH',
						payload: {
							user: {
								email: '',
								firstname: '',
								lastname: '',
								profile_pic: '',
							},
							iam: [],
							authenticated: false,
						},
					})
					setReady(true)
				})
			return () => { }
		} else {
			setReady(true)
		}
		return () => { }
	}, [])

	const onSubmit = async (data) => {
		setError("")
		setLoading(true)

		if (vehicleValue === "") {
			setError("Missing required information")
			return
		}

		if (balanceValue === "") {
			setError("Missing required information")
			return
		}

		if (investorsValue === "") {
			setError("Missing required information")
			return
		}

		if (incomeValue === "") {
			setError("Missing required information")
			return
		}

		if (businessValue === "") {
			setError("Missing required information")
			return
		}

		let bodyFormData = new FormData()
		bodyFormData.append('vehicle', vehicleValue)
		bodyFormData.append('balance', balanceValue)
		bodyFormData.append('income', incomeValue)
		bodyFormData.append('business', businessValue)
		bodyFormData.append('investors', investorsValue)
		bodyFormData.append('regulated', regulatedValue)

		if (statementFile !== null) {
			bodyFormData.append('file', statementFile)
		}

		await AuthService.UpdateWealth(bodyFormData)
			.then((data) => {
				setLoading(false)
				loginDispatch({
					type: 'SET_LOGIN',
					payload: {
						auth: {
							session_id: loginState.auth.session_id,
							account_type: loginState.auth.account_type,
							wealth: true,
							investment: loginState.auth.investment,
							kyc: loginState.auth.kyc,
							video: loginState.auth.video,
							signature: loginState.auth.signature,
						},
					},
				})
				props.onNext()
			})
			.catch((err) => {
				setLoading(false)
				setError(err.message)
			})
		return () => { }
	}

	const formSchema = {}


	function UpdateVehiculeValue(value) {
		if (vehicleValue === value) {
			setVehicleValue("")
			setInvestorsValue("")
			setDisable(true)
		} else {
			setVehicleValue(value)
			if (value === "NO") {
				setInvestorsValue("0")
				setRegulatedValue("NO")
				if (value !== "" && businessValue !== "" && balanceValue !== "" && incomeValue !== "") {
					setDisable(false)
				} else {
					setDisable(true)
				}
			}
			if (value === "YES") {
				setInvestorsValue("")
				setDisable(true)
			}
		}
	}

	function UpdateRegulatedValue(value) {
		if (regulatedValue === value) {
			setRegulatedValue("")
			setInvestorsValue("")
			setDisable(true)
		} else {
			setRegulatedValue(value)
			if (value === "NO") {
				setInvestorsValue("0")
				if (value !== "" && vehicleValue !== "" && businessValue !== "" && balanceValue !== "" && incomeValue !== "") {
					setDisable(false)
				} else {
					setDisable(true)
				}
			}
			if (value === "YES") {
				setInvestorsValue("")
				setDisable(true)
			}
		}
	}

	function UpdateInvestorsValue(value) {
		if (value === "") {
			setInvestorsValue("")
			setDisable(true)
		} else {
			setInvestorsValue(value)
			if (value !== "" && vehicleValue !== "" && businessValue !== "" && incomeValue !== "" && balanceValue !== "") {
				setDisable(false)
			} else {
				setDisable(true)
			}
		}
	}

	function UpdateIncomeValue(value) {
		if (incomeValue === value) {
			setIncomeValue("")
			setDisable(true)
		} else {
			setIncomeValue(value)
			if (vehicleValue !== "" && value !== "" && balanceValue !== "" && investorsValue !== "" && businessValue !== "") {
				setDisable(false)
			}
		}
	}

	function UpdateBalanceValue(value) {
		if (balanceValue === value) {
			setBalanceValue("")
			setDisable(true)
		} else {
			setBalanceValue(value)
			if (vehicleValue !== "" && value !== "" && investorsValue !== "" && businessValue !== "" && incomeValue !== "") {
				setDisable(false)
			}
		}
	}

	function UpdateBusinessValue(value) {
		if (value === "") {
			setBusinessValue("")
			setDisable(true)
		} else {
			setBusinessValue(value)
			if (vehicleValue !== "" && value !== "" && investorsValue !== "" && balanceValue !== "" && incomeValue !== "") {
				setDisable(false)
			}
		}
	}

	async function goBack() {
        setLoading(true)
        let bodyFormData = new FormData()
        bodyFormData.append('step', 'KYB')

        await AuthService.Undo(bodyFormData)
            .then((data) => {
                setLoading(false)
                loginDispatch({
                    type: 'SET_LOGIN',
                    payload: {
                        auth: {
                            session_id: loginState.auth.session_id,
                            account_type: loginState.auth.account_type,
                            wealth: loginState.auth.wealth,
                            investment: loginState.auth.investment,
                            kyc: false,
                            video: loginState.auth.video,
                            signature: loginState.auth.signature,
                        },
                    },
                })
                props.onBack()
            })
            .catch((err) => {
                setLoading(false)
                setError(err.message)
            })
        return () => { }
    }


	return (
		<div>
			<div>
				<SubNav></SubNav>
				<div className='max-w-7xl mx-auto relative'>
					<div className='dashboard-container'>
						<div className='bg-white rounded-xl p-6 shadow'>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div>
									<label
										className="block text-sm font-medium text-gray-700 mb-2">
										Is the entity a collective investment vehicle ?
									</label>
									<div className='flex'>
										<div onClick={() => UpdateVehiculeValue("YES")} className="flex mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={vehicleValue === "YES"} />
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">YES</span>
												</div>
										</div>
										<div onClick={() => UpdateVehiculeValue("NO")} className="flex ml-5 mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={vehicleValue === "NO"}/>
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">NO</span>
												</div>
										</div>
									</div>
									{vehicleValue === "YES" ? <>
										<label
											className="block text-sm font-medium text-gray-700 mb-2 mt-2">
											Is it regulated  ?
										</label>
										<div className='flex'>
											<div onClick={() => UpdateRegulatedValue("YES")} className="flex mr-5 cursor-pointer">
												<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
													type="checkbox"
													checked={regulatedValue === "YES"} />
													<div>
													<span className="text-sm align-middle font-medium text-primary-700">YES</span>
												</div>
											</div>
											<div  onClick={() => UpdateRegulatedValue("NO")} className="flex ml-5 mr-5 cursor-pointer">
												<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
													type="checkbox"
													checked={regulatedValue === "NO"}/>
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">NO</span>
												</div>											</div>
										</div>
									</> : null}
									{regulatedValue === "YES" ? <>
									<div className='mt-2'>
									<Input
											setValue={(e) => UpdateInvestorsValue(e)}
											icon={faUser}
											label='Number of investors'
											placeholder='2'
											name='email'
										/>
									</div>
										
									</> : null}
								</div>
								<div className='mt-5'>
									<label
										className="block text-sm font-medium text-gray-700 mb-2">
										Total assets on balance sheet
									</label>
									<div className='flex'>
										<div onClick={() => UpdateBalanceValue("<1'000'000")} className="flex mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={balanceValue === "<1'000'000"}
												/>
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">&#8804; 1 000 000</span>
												</div>
										</div>
										<div onClick={() => UpdateBalanceValue("1'000'001-5'000'000")} className="flex ml-5 mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={balanceValue === "1'000'001-5'000'000"}
												 />
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">1 000 001 - 5 000 000</span>
												</div>
										</div>
										<div onClick={() => UpdateBalanceValue("5'000'001-10'000'000")} className="flex ml-5 mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={balanceValue === "5'000'001-10'000'000"}
												 />
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">5 000 001 - 10 000 000</span>
												</div>
										</div>
									</div>

									<div className='flex'>
										<div onClick={() => UpdateBalanceValue("10'000'001-25'000'000")} className="flex mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={balanceValue === "10'000'001-25'000'000"} />
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">10 000 001 - 25 000 000</span>
												</div>
										</div>
										<div onClick={() => UpdateBalanceValue("25'000'001-50'000'000")} className="flex ml-5 mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={balanceValue === "25'000'001-50'000'000"}/>
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">25 000 001 - 50 000 000</span>
												</div>
										</div>
										<div onClick={() => UpdateBalanceValue(">50'000'000")} className="flex ml-5 mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={balanceValue === ">50'000'000"} />
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">&#62; 50 000 000</span>
												</div>
										</div>
									</div>
								</div>
								<div className='mt-5'>
									<label
										className="block text-sm font-medium text-gray-700 mb-2">
										Annual Income 2022
									</label>
									<div className='flex'>
										<div onClick={() => UpdateIncomeValue("<1'000'000")} className="flex mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={incomeValue === "<1'000'000"} />
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">&#8804; 1 000 000</span>
												</div>
										</div>
										<div onClick={() => UpdateIncomeValue("1'000'001-5'000'000")} className="flex ml-5 mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={incomeValue === "1'000'001-5'000'000"} />
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">1 000 001 - 5 000 000</span>
												</div>
										</div>
										<div onClick={() => UpdateIncomeValue("5'000'001-10'000'000")} className="flex ml-5 mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={incomeValue === "5'000'001-10'000'000"}/>
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">5 000 001 - 10 000 000</span>
												</div>
										</div>
									</div>

									<div className='flex'>
										<div onClick={() => UpdateIncomeValue("10'000'001-25'000'000")} className="flex mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={incomeValue === "10'000'001-25'000'000"}
												/>
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">10 000 001 - 25 000 000</span>
												</div>
										</div>
										<div onClick={() => UpdateIncomeValue("25'000'001-50'000'000")} className="flex ml-5 mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointers"
												type="checkbox"
												checked={incomeValue === "25'000'001-50'000'000"}
												 />
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">25 000 001 - 50 000 000</span>
												</div>
										</div>
										<div onClick={() => UpdateIncomeValue(">50'000'000")} className="flex ml-5 mr-5 cursor-pointer">
											<input className="checkbox mr-3 ml-2 mt-1 cursor-pointer"
												type="checkbox"
												checked={incomeValue === ">50'000'000"}
												 />
												<div>
													<span className="text-sm align-middle font-medium text-primary-700">&#62; 50 000 000</span>
												</div>
										</div>
									</div>
								</div>
								<div className='mb-5 mt-5'>
									<Input
										setValue={(e) => UpdateBusinessValue(e)}
										icon={faBuilding}
										label='Short narrative of business '
										placeholder='market'
										name='email'
									/>
								</div>
								{error !== "" ? <p className="mt-2 text-sm text-red-600" id="email-error">
									{error}
								</p> : null}
								<label
									className="block text-sm font-medium text-gray-700 mb-2">
									Financial statement (optional)
								</label>
								<Upload
									loading={loading}
									acceptedFileTypes={[
										'image/jpeg',
										'image/png',
										'image/jpg',
									]}
									setFile={setStatementFile}></Upload>
								<div className='flex justify-between mt-10'>
									<div>
										<Button
                                            type='submit'
                                            full
                                            loading={loading}
                                            label='Back'
                                            onClick={() => goBack()}
                                        />
									</div>
									<div>
										<Button
											disabled={disable}
											type='submit'
											theme={"success"}
											full
											loading={loading}
											label='Next'
											onClick={() => onSubmit()}
										/>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
